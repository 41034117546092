
// Layout.jsx

import clsx from 'clsx'
import loadable from '@loadable/component'
import PropTypes from 'prop-types'
import React from 'react'

// internal imports
import { NoSSR } from '../NoSSR'
import { Footer } from '../Footer'
import { Header } from '../Header'

// Loadable imports
const CookieBanner = loadable( () => import( '../CookieBanner/CookieBanner' ) )

const Layout = ( { children, sticky } ) => (

    <div className={ clsx( 'rw-page' ) }>
        <Header sticky={ sticky } />

        { children }

        <Footer />
        <NoSSR><CookieBanner /></NoSSR>
    </div>

)

Layout.propTypes = {
    children: PropTypes.node.isRequired
}

export default Layout
