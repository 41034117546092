
// Header.jsx

import clsx from 'clsx'
import { graphql, Link, useStaticQuery } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import { useLocation } from '@reach/router'

// internal imports
import { NoSSR } from '../NoSSR'
import NavbarLogoDark from '../../assets/images/icon-navbar-dark.png'
import NavbarLogoLight from '../../assets/images/icon-navbar-light.png'

const Header = props => {

    // Component properties
    const { children, sticky, ...rest } = props

    // Component states
    const [ isSticky, setSticky ] = useState( typeof sticky !== 'undefined' ? false : true )

    // DOM references
    const stickyRef = useRef( null )

    const data = useStaticQuery( graphql`
        query SiteNavigationQuery {
            site {
                siteMetadata {
                    menuLinks {
                        link
                        name
                    }
                }
            }
        }
    ` )

    const handleScroll = () => {

        if ( stickyRef.current )

            setSticky( stickyRef.current.className.includes( 'uk-navbar-sticky' ) )

    }

    useEffect( () => {

        if ( typeof window !== 'undefined' )

            window.addEventListener( 'scroll', handleScroll )

        return () => {

            if ( typeof window !== 'undefined' )

                window.removeEventListener( 'scroll', () => handleScroll )

        }

    }, [] )

    const location = useLocation()

    return (
        <>
            <div
                className={ clsx( 'rw-header-mobile', 'uk-hidden@m' ) }
                { ...rest }>

                <div className={ clsx( 'uk-navbar-container' ) }>
                    <nav
                        className={ clsx() }
                        uk-navbar="">

                        <div className={ clsx( 'uk-navbar-left' ) }>
                            <NoSSR>
                                <a
                                    className={ clsx( 'uk-navbar-toggle' ) }
                                    uk-navbar-toggle-icon=""
                                    uk-toggle=""
                                    href="#rw-mobile" />
                            </NoSSR>
                        </div>
                        <div className={ clsx( 'uk-navbar-center' ) }>
                            <Link
                                className={ clsx( 'uk-navbar-item', 'uk-logo' ) }
                                to="/">
                                <img alt="RW" src={ NavbarLogoLight } />
                            </Link>
                        </div>

                    </nav>
                </div>
                <div
                    uk-offcanvas=""
                    id="rw-mobile"
                    mode="slide"
                    overlay="">
                    <div className={ clsx( 'uk-offcanvas-bar' ) }>

                        <NoSSR>
                            <button
                                className={ clsx( 'uk-offcanvas-close' ) }
                                uk-close=""
                                type="button" />
                        </NoSSR>
                        <div
                            className={ clsx( 'uk-child-width-1-1', 'uk-grid-stack' ) }
                            uk-grid="">
                            <div>
                                <div
                                    className={ clsx( 'uk-panel' ) }>
                                    <ul
                                        className={ clsx( 'uk-nav', 'uk-nav-default' ) }>
                                        {
                                            data.site.siteMetadata.menuLinks.map( link => (
                                                <li key={ link.link }>
                                                    <Link
                                                        className={ clsx( '' ) }
                                                        to={ link.link }>
                                                        { link.name }
                                                    </Link>
                                                </li>
                                            ) )
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div
                className={ clsx(
                    'rw-header',
                    'uk-visible@m',
                    !isSticky && 'rw-header-transparent',
                    'rw-header-overlay'
                ) }
                uk-header=""
                { ...rest }>
                <div
                    cls-active={ clsx( 'uk-navbar-sticky' ) }
                    media="@m"
                    sel-target=".uk-navbar-container"
                    uk-sticky={ typeof sticky !== 'undefined' ? JSON.stringify( sticky ) : null }>
                    <div className={ clsx(
                            'uk-navbar-container',
                            !isSticky && 'uk-navbar-transparent',
                            !isSticky ? 'uk-light' : 'uk-dark'
                        ) }
                        ref={ typeof sticky !== 'undefined' ? stickyRef : null }>
                        <div className={ clsx( 'uk-container' ) }>

                            <nav uk-navbar={ JSON.stringify( {
                                align: 'center',
                                boundary: '!.uk-navbar-container'
                            } ) }>
                                <div className={ clsx( 'uk-navbar-center' ) }>

                                    <div className={ clsx( 'uk-navbar-center-left', 'uk-preserve-width' ) }>
                                        <div>
                                            <ul
                                                className={ clsx( 'uk-navbar-nav' ) }
                                                uk-scrollspy-nav={ {
                                                    closest: 'li',
                                                    scroll: true
                                                } }>
                                                <li className={ clsx( location.pathname === data.site.siteMetadata.menuLinks[ 0 ].link && 'uk-active' ) }>
                                                    <Link
                                                        className={ clsx( '' ) }
                                                        to={ data.site.siteMetadata.menuLinks[ 0 ].link }
                                                        uk-scroll={ typeof sticky !== 'undefined' ? '' : null }>
                                                        { data.site.siteMetadata.menuLinks[ 0 ].name }
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className={ clsx( '' ) }
                                                        to={ data.site.siteMetadata.menuLinks[ 1 ].link }
                                                        uk-scroll={ typeof sticky !== 'undefined' ? '' : null }>
                                                        { data.site.siteMetadata.menuLinks[ 1 ].name }
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        className={ clsx( '' ) }
                                                        to={ data.site.siteMetadata.menuLinks[ 2 ].link }
                                                        uk-scroll={ typeof sticky !== 'undefined' ? '' : null }>
                                                        { data.site.siteMetadata.menuLinks[ 2 ].name }
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <Link
                                        className={ clsx(
                                            'uk-navbar-item',
                                            'uk-logo'
                                         ) }
                                        to="/">
                                        <img alt="RW" src={ !isSticky ? NavbarLogoDark : NavbarLogoLight } />
                                    </Link>
                                    <div className={ clsx( 'uk-navbar-center-right', 'uk-preserve-width' ) }>
                                        <div>
                                            <ul className={ clsx( 'uk-navbar-nav' ) }>
                                                <li className={ clsx( location.pathname === data.site.siteMetadata.menuLinks[ 3 ].link && 'uk-active' ) }>
                                                    <Link
                                                        className={ clsx( '' ) }
                                                        to={ data.site.siteMetadata.menuLinks[ 3 ].link }>
                                                        { data.site.siteMetadata.menuLinks[ 3 ].name }
                                                    </Link>
                                                </li>
                                                <li className={ clsx( location.pathname === data.site.siteMetadata.menuLinks[ 4 ].link && 'uk-active' ) }>
                                                    <Link
                                                        className={ clsx( '' ) }
                                                        to={ data.site.siteMetadata.menuLinks[ 4 ].link }>
                                                        { data.site.siteMetadata.menuLinks[ 4 ].name }
                                                    </Link>
                                                </li>
                                                <li className={ clsx( location.pathname === data.site.siteMetadata.menuLinks[ 5 ].link && 'uk-active' ) }>
                                                    <Link
                                                        className={ clsx( '' ) }
                                                        to={ data.site.siteMetadata.menuLinks[ 5 ].link }>
                                                        { data.site.siteMetadata.menuLinks[ 5 ].name }
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </nav>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )

}

export default Header
