
// Footer.jsx

import clsx from 'clsx'
import {
    faDiscord,
    faFacebook,
    faFontAwesome,
    faGithub,
    faInstagram,
    faLinkedin,
    faNodeJs,
    faNpm,
    faReact,
    faSass,
    faStackOverflow,
    faTwitter,
    faUikit,
} from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import React from 'react'

/**
 * Add the Gatsby.js Monogram to the FontAwesome library
 * {@link https://www.gatsbyjs.org/guidelines/logo/}
 * 
 * Circle to path:
 * * M cx, cy
 * * m -r, 0
 * * a r,r 0 1,0  (r * 2),0
 * * a r,r 0 1,0 -(r * 2),0
 * 
 * <circle cx="14" cy="14" r="14" />
 * M 14, 14 m -14, 0 a 14,14 0 1,0 28,0 a 14,14 0 1,0 -28,0
 */
let faGatsby = {
    prefix: 'fab',
    iconName: 'gatsby',
    icon: [
        28,
        28,
        [],
        'f0000',
        'M 14, 14 m -14, 0 a 14,14 0 1,0 28,0 a 14,14 0 1,0 -28,0 M6.2 21.8C4.1 19.7 3 16.9 3 14.2L13.9 25c-2.8-.1-5.6-1.1-7.7-3.2zm10.2 2.9L3.3 11.6C4.4 6.7 8.8 3 14 3c3.7 0 6.9 1.8 8.9 4.5l-1.5 1.3C19.7 6.5 17 5 14 5c-3.9 0-7.2 2.5-8.5 6L17 22.5c2.9-1 5.1-3.5 5.8-6.5H18v-2h7c0 5.2-3.7 9.6-8.6 10.7z'
    ]
}

const Footer = props => {

    // Component properties
    const { children, ...rest } = props

    return (
        <footer
            className={ clsx( 'uk-section', 'uk-section-default' ) }
            uk-scrollspy={ JSON.stringify( {
                cls: 'uk-animation-slide-bottom-medium',
                delay: '200ms',
                target: '[uk-scrollspy-class]'
            } ) }
            { ...rest }>
            <div className={ clsx( 'uk-container' ) }>
                <div
                    className={ clsx( 'uk-grid-margin', 'uk-grid-stack' ) }
                    uk-grid="">
                    <div
                        className={ clsx( 'uk-grid-item-match', 'uk-flex-middle', 'uk-flex-auto', 'uk-width-1-1@m' ) }>
                        <div className={ clsx( 'uk-panel', 'uk-width-1-1' ) }>

                            <h6
                                className={ clsx( 'uk-text-muted', 'uk-margin', 'uk-text-left@m', 'uk-text-center', 'uk-scrollspy-inview', 'uk-animation-slide-bottom-medium' ) }
                                uk-scrollspy-class="">Copyright © Robin Walter { `${ moment().format( 'YYYY' ) }` }.</h6>
                            
                        </div>
                    </div>
                    <div
                        className={ clsx( 'uk-grid-item-match', 'uk-flex-middle', 'uk-flex-auto', 'uk-width-1-1@m', 'uk-first-column' ) }>
                        <div className={ clsx( 'uk-panel', 'uk-width-1-1' ) }>

                            <h5
                                className={ clsx( 'uk-text-muted', 'uk-margin', 'uk-text-center', 'uk-scrollspy-inview', 'uk-animation-slide-bottom-medium' ) }
                                uk-scrollspy-class="">Soziale Netzwerke</h5>

                            <div
                                className={ clsx( 'uk-child-width-auto', 'uk-grid-small', 'uk-flex-center', 'uk-scrollspy-inview' ) }
                                uk-grid=""
                                uk-scrollspy-class="">

                                <div className={ clsx( 'uk-first-cloumn' ) }>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://www.facebook.com/robinwalterfit"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="Facebook">
                                        <Icon icon={ faFacebook } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://www.instagram.com/robinwalterfit"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="Instagram">
                                        <Icon icon={ faInstagram } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://twitter.com/robinwalterfit"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="Twitter">
                                        <Icon icon={ faTwitter } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://discord.com/"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="Discord FivesTanowalker#7014">
                                        <Icon icon={ faDiscord } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://github.com/robinwalterfit"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="GitHub">
                                        <Icon icon={ faGithub } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://stackoverflow.com/users/8844873/robinwalterfit"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="StackOverflow">
                                        <Icon icon={ faStackOverflow } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://www.linkedin.com/in/robinwalterfit/"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="LinkedIn">
                                        <Icon icon={ faLinkedin } size="1x" />
                                    </a>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                    <div
                        className={ clsx( 'uk-grid-item-match', 'uk-flex-middle', 'uk-flex-auto', 'uk-width-1-1@m' ) }>
                        <div className={ clsx( 'uk-panel', 'uk-width-1-1' ) }>

                            <h5
                                className={ clsx( 'uk-text-muted', 'uk-margin', 'uk-text-right@m', 'uk-text-center', 'uk-scrollspy-inview', 'uk-animation-slide-bottom-medium' ) }
                                uk-scrollspy-class="">Built with</h5>

                            <div
                                className={ clsx( 'uk-child-width-auto', 'uk-grid-small', 'uk-flex-right@m', 'uk-flex-center', 'uk-scrollspy-inview' ) }
                                uk-grid=""
                                uk-scrollspy-class="">

                                <div className={ clsx( 'uk-first-cloumn' ) }>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://nodejs.org/"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="Node.js">
                                        <Icon icon={ faNodeJs } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://www.npmjs.com"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="npm">
                                        <Icon icon={ faNpm } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://reactjs.org/"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="React">
                                        <Icon icon={ faReact } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://www.gatsbyjs.org/"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="Gatsby.js">
                                        <Icon icon={ faGatsby } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://sass-lang.com/"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="Sass">
                                        <Icon icon={ faSass } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://getuikit.com/"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="UIkit">
                                        <Icon icon={ faUikit } size="1x" />
                                    </a>
                                </div>
                                <div>
                                    <a
                                        className={ clsx( 'uk-icon-button' ) }
                                        href="https://fontawesome.com/"
                                        rel="noopener noreferrer nofollow"
                                        target="_blank"
                                        uk-tooltip="Font Awesome">
                                        <Icon icon={ faFontAwesome } size="1x" />
                                    </a>
                                </div>

                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )

}

export default Footer
